<template>
  <v-row>
     <!-- header -->
    <mini-header :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main v-if="$helpers.hasSomePermission(['view_supplier'])">
        <v-query-builder class="mx-2" :query-map="filters" model="pos" :disabled="groupsList.length === 0" />
        <v-btn color="primary" @click="handleCreateSegment"><v-icon left>mdi-folder</v-icon>Crear segmento</v-btn>
      </template>
    </mini-header>
   <!-- end header -->

    <PermissionDenied v-if="!$helpers.hasSomePermission(['view_suppliergroup'])" />

    <v-col class="px-0 mt-n10" v-else>
      <VSkeleton class="mt-6" v-if="loading" />
      <template v-else>
        <v-col cols="12" class="pa-0">
          <template v-if="groupsList?.length">
            <div class="mt-5" v-if="groupsList.filter(({visibility}) => visibility !== 3).length">
              <v-toolbar-title class="subtitle-1 defaultGrey--text font-weight-bold mb-4">Segmentos de la organización</v-toolbar-title>
              <v-row>
                <v-col v-for="(d, index) in groupsList.filter(({visibility}) => visibility !== 3)" :key="`${d.id}-${index}`" cols="4" style="max-width: 270px">
                  <v-hover v-slot="{ hover }">
                    <v-card class="pa-5 rounded-md" @click.stop="$router.push({ name: 'SuppliersGroupsRetrieveUpdate', params: {id: d.id}})" :elevation="hover ? 5 : 0" flat>
                      <v-row no-gutters>
                        <v-icon color="primary">mdi-folder</v-icon>
                        <v-spacer />
                        <v-menu v-model="menu[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="10" transition="slide-y-transition" :min-width="182">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                              <v-icon color="secondary" size="24">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0 pb-2">
                            <v-list-item-title class="px-3 pt-4 pb-2 body-1 font-weight-semibold">Accesos directos</v-list-item-title>
                            <v-list-item class="px-3" v-if="$helpers.hasSomePermission(['view_supplier'])" :to="{name: 'SupplierListCreate', query: { groups__in: d.id} }" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los proveedores</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" v-if="$helpers.hasSomePermission(['view_purchase'])" :to="{name: 'PurchaseList', query: { supplier_groups_id: d.id, ...(d.name && { name: d.name })} }" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los documentos</v-list-item-title>
                            </v-list-item>
                            <v-divider class="mx-3 mt-1" />
                            <v-list-item class="px-3 mt-1" @click="addOrRemoveMenu(d)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">{{ verifyShortcutsExist(d) ? 'Eliminar del' : 'Agregar al'}} menú</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleGroup(d)" v-if="$helpers.hasSomePermission(['change_suppliergroup'])" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar segmento</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleDestroySegment(d.id)" v-if="$helpers.hasSomePermission(['delete_suppliergroup'])" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Eliminar segmento</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <span class="d-block pt-2">
                        <span class="fontBody--text font-weight-semibold body-1 d-inline-block text-truncate" style="max-width: 160px;">{{d.name}}</span>
                      </span>
                      <span class="d-block body-1 mt-2 fontDraw--text">Proveedores</span>
                      <span class="d-block fontDraw--text mt-1 body-2">
                        {{d.suppliers.length}}
                      </span>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
            <div class="mt-5" v-if="groupsList.filter(({visibility}) => visibility === 3).length">
              <v-toolbar-title class="subtitle-1 defaultGrey--text font-weight-bold mb-4">Tus segmentos</v-toolbar-title>
              <v-row>
                <v-col v-for="(d, index) in groupsList.filter(({visibility}) => visibility === 3)" :key="`${d.id}-${index}`" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                  <v-hover v-slot="{ hover }">
                    <v-card class="pa-5 rounded-md" @click.stop="$router.push({ name: 'SuppliersGroupsRetrieveUpdate', params: {id: d.id}})" :elevation="hover ? 5 : 0" flat>
                      <v-row no-gutters>
                        <v-icon color="primary">mdi-folder</v-icon>
                        <v-spacer />
                        <v-menu v-model="menu1[index]" close-on-click offset-y left :nudge-bottom="0" :nudge-left="10" transition="slide-y-transition" :min-width="182">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on" :ripple="false">
                              <v-icon color="secondary" size="24">mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list class="pa-0 pb-2">
                            <v-list-item-title class="px-3 pt-4 pb-2 body-1 font-weight-semibold">Accesos directos</v-list-item-title>
                            <v-list-item class="px-3" :to="{name: 'SupplierListCreate', query: { groups__in: d.id} }" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los proveedores</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" :to="{name: 'PurchaseList', query: { supplier_groups_id: d.id, ...(d.name && { name: d.name })} }" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Ir a los documentos</v-list-item-title>
                            </v-list-item>
                            <v-divider class="mx-3 mt-1" />
                            <v-list-item class="px-3 mt-1" @click="addOrRemoveMenu(d)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">{{ verifyShortcutsExist(d) ? 'Eliminar del' : 'Agregar al'}} menú</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleGroup(d)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar segmento</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="handleDestroySegment(d)" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Eliminar segmento</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <span class="d-block pt-2">
                        <span class="fontBody--text font-weight-semibold body-1 d-inline-block text-truncate" style="max-width: 160px;">{{d.name}}</span>
                      </span>
                      <span class="d-block body-1 mt-2 fontDraw--text">Proveedores</span>
                      <span class="d-block fontDraw--text mt-1 body-2">
                        {{d.suppliers.length}}
                      </span>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
        <v-empty-state v-if="!groupsList.length" title="No existen segmentos para mostrar" :isFree="true" type="suppliers" :height="env ? 320 : 200" customClass="mt-5" />
      </template>
    </v-col>

    <!-- dialog edit group -->
    <v-dialog v-model="dialogSegment" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Editar segmento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogSegment=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.name.$model" :error="$v.editable.name.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-1">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.editable.description.$model" :error="$v.editable.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div>
            <span class="d-block mt-5 body-1 fontBody--text font-weight-bold">Visibilidad</span>
            <v-radio-group class="mx-0 ml-n1" v-model="editable.visibility" dense hide-details>
              <v-radio label="Todos los miembros de la organización" :value="1" hide-details />
              <v-radio label="Algunos miembros de la organización" :value="2" hide-details />
              <v-radio label="Solo yo" :value="3" hide-details />
            </v-radio-group>
          </div>
          <template v-if="![1, 3].includes(editable.visibility)">
            <v-list class="pa-0 mt-5 transparent v-list-form-extended">
              <v-list-item class="py-1">
                <v-list-item-title>Compartir con</v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="editable.members"
                      :error="$v.editable.members.$error"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      return-object
                      :prefix="editable.members.length >= 1 ? `${editable.members.length === 1 ? `${editable.members.length} seleccionado` : `${editable.members.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row>
              <v-col cols="2"></v-col>
              <v-col class="pl-9 mt-2">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in editable.members" :key="`${member}${i}`" close @click:close="remove(member)" color="lightBlue" small label close-icon="mdi-close">
                  {{ member.email }}
                </v-chip>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogSegment=false">Cancelar</v-btn>
          <v-btn color="primary" @click="editSegment" :loading="editSegmentLoading">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog edit group -->

     <!-- dialog confirm delete -->
     <v-dialog v-model="dialogDelete" width="709" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Eliminar segmento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pl-5" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-segment-destroy.svg`)" :width="150" />
            </v-col>
            <v-col class="pl-6 pr-0">
              <span class="d-block">Al eliminar el segmento, los proveedores asociados a éste seguirán activos y podrán ser asignados a otros segmentos en el futuro.</span>
              <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroySegment" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog new group -->
    <v-dialog v-model="dialogCreateGroup" width="650" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Crear segmento</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogCreateGroup=false" icon small><v-icon size="24">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 pt-6 px-5" style="max-height: 600px !important;">
          <v-list class="pa-0 transparent v-list-form-extended">
            <v-list-item class="py-1">
              <v-list-item-title>Nombre</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.name.$model" :error="$v.newGroup.name.$error" outlined required single-line dense maxlength="64" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-2">
              <v-list-item-title>Descripción</v-list-item-title>
              <v-list-item-subtitle>
                <v-text-field v-model="$v.newGroup.description.$model" :error="$v.newGroup.description.$error" outlined required single-line dense maxlength="100" hide-details />
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <div class="">
            <span class="d-block mt-5 body-1 fontBody--text font-weight-bold">Visibilidad</span>
            <v-radio-group class="mx-0 ml-n1" v-model="newGroup.visibility" dense hide-details>
              <v-radio label="Todos los miembros de la organización" :value="1" hide-details />
              <v-radio label="Algunos miembros de la organización" :value="2" hide-details />
              <v-radio label="Solo yo" :value="3" hide-details />
            </v-radio-group>
          </div>
          <template v-if="![1, 3].includes(newGroup.visibility)">
            <v-list class="pa-0 mt-5 transparent v-list-form-extended">
              <v-list-item class="py-1">
                <v-list-item-title>Compartir con</v-list-item-title>
                <v-list-item-subtitle>
                  <v-col class="pa-0">
                    <v-autocomplete
                      v-model="newGroup.members"
                      :error="$v.newGroup.members.$error"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="membersList"
                      multiple
                      :chips="false"
                      item-text="email"
                      item-value="email"
                      return-object
                      :prefix="newGroup.members.length >= 1 ? `${newGroup.members.length === 1 ? `${newGroup.members.length} seleccionado` : `${newGroup.members.length} seleccionados`}` : ''"
                      no-data-text="No existen miembros para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-col>
                </v-list-item-subtitle>
              </v-list-item>
            </v-list>
            <v-row class="ml-1" no-gutters>
              <v-col cols="2"></v-col>
              <v-col class="pa-0 mt-2 pl-6">
                <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(member, i) in newGroup.members.slice(0,2)" :key="`${member}${i}`" close @click:close="memberRemove(member, i)" color="lightBlue" small label close-icon="mdi-close">
                  {{member.email }}
                </v-chip>
                <template v-if="newGroup.members.length > 2">
                  <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" color="lightBlue" small label close-icon="mdi-close">
                  {{newGroup.members.length - 2}} más
                </v-chip>
                </template>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer/>
          <v-btn outlined @click="dialogCreateGroup=false">Cancelar</v-btn>
          <v-btn color="primary" @click="createGroup" :loading="createGroupLoading">Crear</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog new group -->
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import PermissionDenied from '@/modules/http/views/PermissionDenied'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VTableHeaders from '@/components/VTable/VTableHeaders'
import MiniHeader from '@/components/commons/MiniHeader'
import { required, maxLength, requiredIf } from 'vuelidate/lib/validators'
import VEmptyState from '@/components/commons/VEmptyState'
import VCardList from '@/modules/pos/pos/components/complements/VCardList'
import VHeader from '@/modules/pos/pos/components/complements/VHeader'
import VSkeleton from '@/modules/pos/pos/components/complements/VSkeletonPos'
import ListViewMixin from '@/mixins/ListViewMixin'
import VQueryBuilder from '@/components/VQueryBuilder/VQueryBuilder'

export default {
  components: {
    VTableHeaders,
    SkeletonList,
    VEmptyState,
    VCardList,
    VHeader,
    VSkeleton,
    VQueryBuilder,
    MiniHeader,
    PermissionDenied
  },
  mixins: [
    ListViewMixin
  ],
  data: () => ({
    dialogCreateGroup: false,
    dialogGroup: false,
    createGroupLoading: false,
    shortcutsSegment: [],
    confirm: '',
    loadingSuppliers: false,
    dialogDelete: false,
    editSegmentLoading: false,
    dialogSegment: false,
    menu: [false],
    menu1: [false],
    scroll: 0,
    loading: false,
    filters: [
      {
        type: 'text',
        id: 'name',
        label: 'Nombre',
        value: null,
        category: 'a',
        operators: [''],
        countries: ['PE', 'CL']
      }
    ],
    editable: {
      name: '',
      visibility: 3,
      description: '',
      pos: [],
      members: []
    },
    currentDelete: '',
    breadcrumbs: {
      main: 'Recepción',
      children: [
        {
          text: 'Segmentos'
        }
      ]
    },
    env: process.env?.VUE_APP_MODE ?? 'ax',
    newGroup: {
      name: '',
      visibility: 3,
      description: '',
      suppliers: [],
      members: []
    }
  }),
  /* eslint-disable */
  computed: {
    ...mapState({
      groupsList: state => state.suppliers.groupsList,
      membersList: state => state.accounts.membersList,
      suppliersList: state => state.suppliers.suppliersList
    }),
    verifyShortcutsExist () {
      return (segment = {}) => {
        let recovery = JSON.parse(localStorage.getItem('shortcuts-segment')) ?? []
        const index = recovery?.findIndex(({account_id}) => account_id === this.$store.state.auth.account.id)
        if (index !== -1) return (!!recovery[index].shortcuts.find(({id}) => id === segment.id))
        return false
      }
    }
  },
  created () {
    this.getList()
  },
  methods: {
    handleCreateSegment () {
        this.dialogCreateGroup = true
        this.$store.dispatch('accounts/LIST', {
          resource: 'members',
          query: {
            page_size: 9999,
            is_active: true
          }
        })
    },
    createGroup () {
      this.$v.newGroup.$touch()
      if (this.$v.newGroup.$invalid) {
        return false
      }

      this.createGroupLoading = true
      let currentQuery = JSON.parse(JSON.stringify(this.$route.query))
      if (currentQuery.ordering) delete currentQuery.ordering
      let payload = this.newGroup
      payload.suppliers = this.selectedList
      if ([1, 3].includes(this.newGroup.visibility)) delete payload.members
      else payload.members = payload.members.map(({id}) => id)

      if (['todos', 'filtrados'].includes(this.actionSelected) || ['filtered', 'all'].includes(this.$store.state.documents.sendTypeToggleDropdown)) delete payload.suppliers
      this.$store.dispatch('suppliers/CREATE', {
        resource: 'suppliers/groups',
        query: currentQuery,
        payload: payload
      })
     .then(() => {
        this.$dialog.message.info('El segmento ha sido creado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.newGroup.$reset()
        this.dialogCreateGroup = false
        this.createGroupLoading = false
        this.newGroup = {
          name: '',
          visibility: 3,
          description: '',
          suppliers: [],
          members: []
        }
      })
    },
    addOrRemoveMenu (segment = {}) {
      this.shortcutsSegment = []
      this.shortcutsSegment.push({
        id: segment.id,
        name: segment.name
      })
      let recovery = JSON.parse(localStorage.getItem('shortcuts-segment')) ?? []
      const index = recovery?.findIndex(({account_id}) => account_id === this.$store.state.auth.account.id)
      if (index !== -1) {
        if (!this.verifyShortcutsExist(segment)) {
          if (recovery[index].shortcuts.length === 3) {
            this.$dialog.message.error('Solo puedes crear hasta 3 accesos directos')
          } else {
            if (!recovery[index].shortcuts.find(({id}) => id === segment.id)) recovery[index].shortcuts = [...recovery[index].shortcuts, ...this.shortcutsSegment]
          }
        } else {
          recovery[index].shortcuts = recovery[index].shortcuts.filter(({id}) => id !== segment.id)
        }
      } else {
        recovery.push({
          account_id: this.$store.state.auth.account.id,
          type: 'Recepción',
          shortcuts: this.shortcutsSegment
        })
      }
      localStorage.setItem('shortcuts-segment', JSON.stringify(recovery))
      this.$store.commit('suppliers/SET_SHORTCUTS')
    },
    onScroll (e) {
      this.offsetTop = e.target?.scrollingElement?.scrollTop ?? 0
    },
    getList () {
      this.loading = true
      this.$store.dispatch('suppliers/LIST', {
        resource: 'suppliers/groups',
        query: this.$route.query
      })
      .finally(() => {
        this.loading = false
      })
    },
    handleGroup (item = {}) {
      this.dialogSegment = true
      this.$store.dispatch('accounts/LIST', {
        resource: 'members',
        query: {
          page_size: 9999,
          is_active: true
        }
      })
      .then((resp) => {
        this.editable = {
          id: item.id,
          name: item.name,
          visibility: item.visibility,
          description: item.description,
          pos: item.pos,
          members: resp.data.results.filter(({id}) => item.members.includes(id))
        }
      })
    },
    memberRemove (item) {
      const index = this.editable.members.indexOf(item)
      if (index >= 0) this.editable.members.splice(index, 1)
    },
    handleDestroySegment (segment = '') {
      this.currentDelete = segment
      this.dialogDelete = true
    },
    editSegment () {
      this.$v.editable.$touch()
      if (this.$v.editable.$invalid) {
        return false
      }

      this.editSegmentLoading = true

      let payload = {...this.editable}
      if ([1, 3].includes(this.editable.visibility)) delete payload.members
      else payload.members = payload.members.map(({id}) => id)

      delete payload.id

      this.$store.dispatch('suppliers/UPDATE', {
        resource: 'suppliers/groups',
        id: this.editable.id,
        query: this.$route.query,
        payload: payload
      })
     .then(() => {
        this.$dialog.message.info('El segmento se ha actualizado con éxito.')
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.$v.editable.$reset()
        this.editable = {
          name: '',
          visibility: 3,
          description: '',
          pos: [],
          members: []
        }
        this.dialogSegment = false
        this.editSegmentLoading = false
      })
    },
    destroySegment () {
      this.$store.dispatch('suppliers/DESTROY', {
        resource: 'suppliers/groups',
        id: this.currentDelete
      })
      .then(() => {
        this.$dialog.message.info('El segmento se ha sido eliminado con éxito')
        this.getList()
        this.addOrRemoveMenu(this.currentDelete)
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.currentDelete = ''
        this.confirm = ''
        this.dialogDelete = false
      })
    },
    remove (item) {
      const index = this.editable.members.indexOf(item)
      if (index >= 0) this.editable.members.splice(index, 1)
    }
  },
  validations: {
    newGroup: {
      name: { required, maxLength: maxLength(64) },
      description: { required, maxLength: maxLength(64) },
      visibility: { required },
      members: {
      required: requiredIf(function () {
        return ![1, 3].includes(this.newGroup.visibility)
      })
      }
    },
    editable: {
      name: { required, maxLength: maxLength(100) },
      description: { required, maxLength: maxLength(100) },
      visibility: { required },
      members: {
      required: requiredIf(function () {
        return ![1, 3].includes(this.editable.visibility)
      })
      }
    }
  }
}
</script>